import React from "react"
import {Button, Col, Container, Row} from "react-bootstrap"
import {Link, graphql} from "gatsby";
import Layout from "../components/layout"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import Img from "gatsby-image"


function PromoPage(props) {

    var debug = require('debug')('PromoPage')

	var ind = 0;
	return(
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="Promozioni" keywords={["Promozioni per cerimonie", "Pacchetto scontato intrattenimento musicale"]}/>
            <Container className="mt-5">
                <h1 className="mb-5 text-center">PROMOZIONI</h1>
			</Container>	
			{
				props.data.allPromoJson.edges.map(promo => {
					ind++	
					const title = (
						<h3 className="my-4">{promo.node.title}</h3>
					)
					
					
					let stripped = promo.node.body
                    promo.node.replacement.forEach((item) => {
                        let re = new RegExp(item.placeholder, "g")
                        stripped = stripped.replace(re, item.value)
                    })
					
					
					const body = (
						<div dangerouslySetInnerHTML={{__html: stripped}}></div>
					)
					
					const image = props.data.allFile.edges.filter((file) => {
							return file.node.relativePath === promo.node.img
						}
					)
					
					var orderClass = (ind%2 == 0) ? "order-xs-last order-md-first": "order-xs-last order-md-last"
					return (
					<Container fluid className="my-5" key={promo.node.id}>
					<Row>
                    <Col sm={12} md={6} lg={4} className={orderClass}>
						{title}
                        {body}
						<div className="mb-5 text-center">
                            <Link to={"/promo/" + promo.node.slug } className="link-no-style">
							    <Button variant="primary" size="lg" className="round-button" active>Blocca la promo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={8}>
                        <Link to={"/promo/" + promo.node.slug } className="link-no-style">
							<Img className="d-block w-100" fluid={image[0].node.childImageSharp.fluid}/>
						</Link>
                    </Col>
					</Row>
					</Container>
					)
				})
			}

        </Layout>
	)
}

export default PromoPage

export const query = graphql`
    query {
        allPromoJson{
			edges{
				node {
                  id
                  slug
                  title
                  body
				  img
                  replacement{
                      placeholder
                      value
                  }
				}
			}
        }
        allFile(filter: {extension: {in: "jpg"}}) {
            edges {
                node {
					relativePath
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`